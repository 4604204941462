import { ContentEditable, ContentEditableProps as ContentEditableProps } from "@lexical/react/LexicalContentEditable";
import { alpha, styled } from "@mui/material";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import type { EditorThemeClasses } from "lexical";

const classPrefix = "SinchTextEditor";

const textClasses = generateUtilityClasses(classPrefix, [
  "bold",
  "code",
  "italic",
  "strikethrough",
  "subscript",
  "superscript",
  "underline",
  "underlineStrikethrough",
]);

const tableClasses = generateUtilityClasses(classPrefix, [
  "table",
  "tableAddColumns",
  "tableAddRows",
  "tableCellActionButton",
  "tableCellActionButtonContainer",
  "tableCellSelected",
  "tableCell",
  "tableCellHeader",
  "tableCellResizer",
  "tableRow",
  "tableScrollableWrapper",
  "tableSelected",
  "tableSelection",
]);
const editorClasses = {
  text: textClasses,
  ...tableClasses,
};
export const editorTheme = editorClasses as EditorThemeClasses;

export const ContentEditableStyled = styled(ContentEditable, {
  shouldForwardProp: (prop) => prop !== "maxRows" && prop !== "minRows",
})<ContentEditableProps & { maxRows?: number; minRows?: number }>(({ theme, maxRows, minRows }) => ({
  padding: theme.spacing(1),
  minHeight: minRows ? `${minRows * 1.3}rem` : "5rem",
  maxHeight: maxRows ? `${maxRows * 1.3}rem` : undefined,
  overflowY: maxRows ? "auto" : undefined,
  "&:focus-visible": {
    border: "none",
    outline: "none",
  },
  [`& .${textClasses.bold}`]: {
    fontWeight: "bold",
  },
  [`& .${textClasses.italic}`]: {
    fontStyle: "italic",
  },
  [`& .${textClasses.underline}`]: {
    textDecoration: "underline",
  },
  [`& .${textClasses.strikethrough}`]: {
    textDecoration: "line-through",
  },
  [`& .${textClasses.underlineStrikethrough}`]: {
    textDecoration: "underline line-through",
  },
  [`& .${textClasses.subscript}`]: {
    fontSize: "0.8em",
    verticalAlign: "sub !important",
  },
  [`& .${textClasses.superscript}`]: {
    fontSize: "0.8em",
    verticalAlign: "super",
  },
  [`& .${textClasses.superscript}`]: {
    fontSize: "0.8em",
    verticalAlign: "super",
  },
  [`& .${tableClasses.tableCell}`]: {
    border: `1px solid ${theme.palette.grey[800]}`,
  },
  [`& .${tableClasses.tableCellSelected}`]: {
    backgroundColor: alpha(theme.palette.custom.blue["600"], theme.palette.action.selectedOpacity),
  },
  [`& .${tableClasses.tableCellHeader}`]: {
    backgroundColor: theme.palette.grey["200"],
  },
  "& .TableCellResizer__resizer": {
    position: "absolute",
    touchAction: "none",
  },

  "@media (pointer: coarse)": {
    "& .TableCellResizer__resizer": {
      position: "relative",
      backgroundColor: "#adf",
      mixBlendMode: "color",
    },
  },
}));
