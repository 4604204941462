import { AutoLinkNode, LinkNode } from "@lexical/link";
/* eslint-disable import/no-internal-modules */
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin as LexicalLinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
/* eslint-enable import/no-internal-modules */
import { Divider, Stack } from "@mui/material";
import React, { FC, useMemo } from "react";

import { Bold } from "../commands/Bold";
import { Italic } from "../commands/Italic";
import { Link } from "../commands/Link";
import { LinkFloatingBox } from "../commands/LinkFloatingBox";
import { Underline } from "../commands/Underline";
import { ChangeHandler } from "../components/ChangeHandler";
import { Toolbar } from "../components/Toolbar";
import { ToolbarDivider } from "../components/ToolbarDivider";
import { MaxLengthPlugin } from "../plugins/MaxLengthPlugin";
import { ContentEditableStyled, editorTheme } from "../TextEditorTheme";
import { TextEditorVariantProps } from "./types";

export const MinimalTextEditor: FC<TextEditorVariantProps> = ({
  onFocus,
  onBlur,
  innerRef,
  disabled,
  maxRows,
  minRows,
  maxLength,
  toolbarPosition,
  secondaryToolbar,
}) => {
  const editorConfig: InitialConfigType = useMemo(
    () => ({
      namespace: "MinimalTextEditor",
      editable: !disabled,
      // Handling of errors during update
      onError(error) {
        throw error;
      },
      theme: editorTheme,
      // Any custom nodes go here
      nodes: [AutoLinkNode, LinkNode],
    }),
    [disabled]
  );

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Stack
        direction={toolbarPosition === "bottom" ? "column-reverse" : "column"}
        sx={{
          width: "100%",
        }}
      >
        <Toolbar>
          <Bold />
          <Italic />
          <Underline />
          <ToolbarDivider />
          <Link />
        </Toolbar>
        <Divider />
        <RichTextPlugin
          contentEditable={
            <ContentEditableStyled maxRows={maxRows} minRows={minRows} onBlur={onBlur} onFocus={onFocus} />
          }
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={<></>}
        />
        {secondaryToolbar && (
          <>
            <Divider />
            <Toolbar>{secondaryToolbar}</Toolbar>
          </>
        )}
      </Stack>
      <ChangeHandler innerRef={innerRef} />
      <HistoryPlugin />
      <LexicalLinkPlugin />
      {maxLength ? <MaxLengthPlugin maxLength={maxLength} /> : <></>}
      <LinkFloatingBox />
    </LexicalComposer>
  );
};
