import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { Tooltip } from "@mui/material";
import { MdsTableXs } from "@sinch/icons";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { ToolbarButton } from "../components/ToolbarButton";

export const Table: FC = () => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();

  const insertPlaceholder = useCallback(() => {
    editor.dispatchCommand(INSERT_TABLE_COMMAND, { columns: "3", rows: "3", includeHeaders: false });
  }, [editor]);

  const disabled = !editor.isEditable();

  return (
    <Tooltip placement="top" title={t("TextEditor.table")}>
      <ToolbarButton disabled={disabled} onClick={insertPlaceholder} tabIndex={-1} value="table">
        <MdsIcon fontSize="xs" icon={MdsTableXs} />
      </ToolbarButton>
    </Tooltip>
  );
};
